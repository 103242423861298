import React from "react"

import { DatePicker as AntDatePicker, TimeRangePickerProps as AntDatePickerProps } from "antd"
import IMask from "imask"
import moment from "moment"

const { RangePicker } = AntDatePicker

const DATE_FORMAT = "DD.MM.YYYY"
const MASKED = IMask.createMask({
    blocks: {
        DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
        MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
        YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE }
    },
    format: (date: Date) => moment(date).format(DATE_FORMAT),
    mask: Date,
    parse: (date: string) => moment(date, DATE_FORMAT),
    pattern: DATE_FORMAT
})

export type DatePickerProps = Omit<AntDatePickerProps, "format" | "picker" | "onKeyDown">

export const CustomRangePicker = (props: DatePickerProps) => (
    <RangePicker
        format={DATE_FORMAT}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            const input = event.target as HTMLInputElement
            input.value = MASKED.resolve(input.value)
        }}
        picker="date"
        placeholder={["С", "По"]}
        {...props}
    />
)