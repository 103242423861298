import { useCallback, useContext } from "react"

import { RoleContext } from "@/context/RoleContext"

export const useRole = () => {

    const context = useContext(RoleContext)

    const hasRole = useCallback((name: string) => {
        return context.some(w => w.name === name)
    }, [context])

    return {
        hasRole
    }
}