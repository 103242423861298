import { FC } from "react"

import { Button, Descriptions, Empty, Skeleton, Space } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import classes from "./OwnerById.module.scss"

import { useOwnerByIdQuery } from "@/hook/Vocabularies/useOwnerQuery"

import { paths } from "@/router/paths"

const OwnerById: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id?: string }>()

  const { data, isLoading, isError } = useOwnerByIdQuery(Number(id))

  const onBackHandler = () => {
    navigate(paths.owners)
  }

  if (isError) {
    return <Empty description="Собственник не найден" />
  }

  return (
    <Skeleton loading={isLoading}>
      {data && (
        <>
          <Descriptions title={`Собственник ${data.response.number}`} layout="vertical" bordered>
            <Descriptions.Item label="Номер записи" style={{ width: "33%" }}>{data.response.number}</Descriptions.Item>
            <Descriptions.Item label="Полное наименование" style={{ width: "33%" }}>{data.response.name}</Descriptions.Item>
            <Descriptions.Item label="Сокращенное наименование" style={{ width: "33%" }}>{data.response.shortName}</Descriptions.Item>    
            <Descriptions.Item label="Идентификатор">{data.response.identifier}</Descriptions.Item>
            <Descriptions.Item label="КПП">{data.response.kpp}</Descriptions.Item>
            <Descriptions.Item label={data.response.isIndividualEntrepreneur && data.response.type.id === 1 ? "ОГРНИП" : "ОГРН"}>
              {data.response.ogrn}
            </Descriptions.Item>
            <Descriptions.Item label="Является ИП">{data.response.isIndividualEntrepreneur ? "Да" : "Нет"}</Descriptions.Item>  
            <Descriptions.Item label="Тип владельца / Форма собственности">{data.response.type.value}</Descriptions.Item>  
          </Descriptions>
          <Space className={classes.buttons}>
            <Button onClick={onBackHandler}>Назад к списку</Button>
          </Space>
        </>
      )}
    </Skeleton>
  )
}

export default OwnerById