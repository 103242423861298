import { FC, Key, useRef, useState, useEffect } from "react"

import { useNavigate, useSearchParams } from "react-router-dom"

import { ClientAddForm, ClientAddFormProps } from "@/components/Client/ClientAddForm"
import { Table } from "@/components/Table"
import { Resource } from "@/constants/permission"
import { clientColumns } from "@/pages/Clients/ClientColumns"
import { useClientsFilters } from "@/pages/Clients/ClientsFilters"

import { useLocationSearchHandler } from "@/hook/useLocationSearchHandler"
import { useTable } from "@/hook/useTable"
import { useClientMutation } from "@/hook/Vocabularies/useClientMutation"
import { useClientQuery } from "@/hook/Vocabularies/useClientQuery"

import { CLIENTS_KEY, IClient } from "@/types/IClient"

import { paths } from "@/router/paths"

import { defineLoading } from "@/utils/defineLoading"

const ClientsPage: FC = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const clientComponent = useRef<ClientAddFormProps>(null)

    const { filters } = useClientsFilters()

    const { deleteMutation } = useClientMutation()

    const { columns, pagination, filter, order } = useTable({
        initialColumns: clientColumns,
        key: CLIENTS_KEY
    })

    const { data, isLoading } = useClientQuery({
        Page: pagination.page,
        PageSize: pagination.pageSize,
        Filter: filter.filter,
        OrderBy: order.orderBy
    })

    const [renderLoading, setRenderLoading] = useState(true)

    useEffect(() => {
        defineLoading(data && !isLoading, setRenderLoading)
    }, [data, isLoading])

    const onRowClickHandler = (data: IClient) => {
        navigate(paths.clientById(data.id))
    }

    const onAddHandler = () => {
        clientComponent.current?.open()
    }

    const onEditHandler = (id: number) => {
        clientComponent.current?.open(id)
    }

    const onRemoveHandler = async (ids: Key[]) => {
        await deleteMutation.mutateAsync(ids.map((id) => Number(id)))
    }

    const onOpenForEdit = () => {
        const searchId = searchParams.get("edit")
        if (searchId) {
            onEditHandler(Number(searchId))
        }
    }

    useLocationSearchHandler({
        value: "edit",
        handler: onOpenForEdit
    })

    useLocationSearchHandler({
        value: "openForm",
        handler: onAddHandler
    })

    return (
        <>
            <ClientAddForm ref={clientComponent} />
            <Table
            tableName={CLIENTS_KEY}
                dataSource={data?.response.data}
                columns={columns.data}
                rowKey="id"
                isLoading={renderLoading}
                pagination={{
                    currentPage: pagination.page,
                    pageSize: pagination.pageSize,
                    total: data?.response.count,
                    onPageChange: pagination.onPageChange,
                    onPageSizeChange: pagination.onPageSizeChange
                }}
                filters={{
                    filters,
                    onSubmit: filter.onFilterChange,
                    onCancel: filter.onFilterReset,
                    filterKey: CLIENTS_KEY
                }}
                onAdd={onAddHandler}
                onEdit={onEditHandler}
                onRemove={onRemoveHandler}
                addText="Добавить клиента"
                onRowClick={onRowClickHandler}
                onSorterChange={order.setOrderBy}
                permission={{
                    resource: Resource.Client
                }}
            />
        </>
    )
}

export default ClientsPage