import { ITableColumn } from "@/types/ui"

export const getInitialColumns = <T>(defaultColumns: ITableColumn<T>[], localStorageKey: string | null): ITableColumn<T>[] => {
  const savedColumns = localStorageKey ? localStorage.getItem(localStorageKey) : null

  if (!savedColumns) {
    return defaultColumns
  }

  const savedColumnsParse = JSON.parse(savedColumns) as ITableColumn<T>[]

  return savedColumnsParse.map((column) => ({
    ...column,
    render: defaultColumns.find(c => c.key === column.key)?.render
  }))
}