import { useEffect, useState } from "react"

import { FieldData } from "@/components/FilterWrapper"

import { useFilterStorage } from "@/hook/useFilterStorage"

import { CLIENTS_KEY } from "@/types/IClient"
import { FilterField, FilterFieldOperator, FilterFieldType, IFilterField } from "@/types/ui"

const clientsFilters: IFilterField[] = [
    {
        text: "Внутренний номер",
        name: "Number",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    },
    {
        text: "Полное наименование",
        name: "Name",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    },
    {
        text: "ИНН",
        name: "Inn",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    },
    {
        text: "№ Р/СЧ в Банке",
        name: "bankAccount",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    },
    {
        text: "БИК",
        name: "bik",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    },
    {
        text: "№ К/СЧ",
        name: "correspondentAccount",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    },
    {
        text: "Электронная почта",
        name: "Email",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    },
    {
        text: "Номер телефона",
        name: "Phone",
        type: FilterFieldType.Text,
        operator: FilterFieldOperator.Contains
    }
]

export const useClientsFilters = () => {
    const [filters, setFilters] = useState<FilterField[]>([])

    const { getFilter } = useFilterStorage({
        key: CLIENTS_KEY
    })

    const oldFilters: FieldData[] = getFilter()

    useEffect(() => {
        setFilters([...clientsFilters])
    }, [])

    return {
        filters: filters.map(f => {
            const currentFilter = oldFilters.find(i => (i.name as string[])[0] === f.name)

            if (!currentFilter || !currentFilter.value) {
                return f
            }

            return {
                ...f, value: currentFilter.value
            }
        })
    }
}