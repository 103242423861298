import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IAuthorizedOrganization, IAuthorizedOrganizationCreate, IAuthorizedOrganizationUpdate } from "@/types/IAuthorizedOrganization"

export const getAuthorizedOrganizations = (params: IRequestParams): Promise<IPagingResponse<IAuthorizedOrganization>> =>
  client.get("/AuthorizedOrganizations", {
    params
  })

export const getAuthorizedOrganizationById = (id: number): Promise<IResponse<IAuthorizedOrganization>> =>
  client.get(`/AuthorizedOrganizations/${id}`)

export const createAuthorizedOrganization = (body: IAuthorizedOrganizationCreate): Promise<IResponse<number>> =>
  client.post("/AuthorizedOrganizations", body)

export const updateAuthorizedOrganization = (body: IAuthorizedOrganizationUpdate): Promise<IResponse<string>> =>
  client.put("/AuthorizedOrganizations", body)

export const deleteAuthorizedOrganizations = (ids: number[]): Promise<IResponse<boolean>> =>
  client.delete("/AuthorizedOrganizations", {
    data: {
      ids
    }
  })