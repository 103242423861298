import { AxiosRequestConfig } from "axios"

import { client } from "@/http/client"

import { IPagingResponse, IRequestParams, IResponse } from "@/types/api"
import { IDownloadDocument } from "@/types/IDownloadDocument"
import {
    IEpsmApplication,
    IEpsmApplicationCopyDto,
    IEpsmApplicationCreateDto,
    IEpsmApplicationCreateResponse,
    IEpsmApplicationUpdateDto
} from "@/types/IEpsmApplication"
import { ITableColumn } from "@/types/ui"

export const getEpsmApplications = (params: IRequestParams): Promise<IPagingResponse<IEpsmApplication>> =>
  client.get("/EpsmApplications", {
    params
  })

export const getEpsmApplicationById = (id: string): Promise<IResponse<IEpsmApplication>> => client.get(`/EpsmApplications/${id}`)

export const createEpsmApplication = (formData: FormData, config: AxiosRequestConfig): Promise<IResponse<IEpsmApplicationCreateResponse>> =>
  client.post("/EpsmApplications", formData, {
    headers: { "content-type": "multipart/form-data" },
    ...config
  })

export const confirmCreationEpsmApplication = (id: number): Promise<IResponse<boolean>> =>
  client.post(`/EpsmApplications/ConfirmCreation/${id}`)

export const createManuallyEpsmApplication = (dto: IEpsmApplicationCreateDto): Promise<IResponse<string>> =>
  client.post("/EpsmApplications/CreateManually", dto)

export const updateEpsmApplication = (dto: IEpsmApplicationUpdateDto): Promise<IResponse<boolean>> => client.put("/EpsmApplications", dto)

export const updateManuallyEpsmApplication = (dto: IEpsmApplicationUpdateDto): Promise<IResponse<boolean>> =>
  client.put("/EpsmApplications/UpdateManually", dto)

export const copyEpsmApplication = (body: IEpsmApplicationCopyDto): Promise<IResponse<string>> =>
  client.post("/EpsmApplications/Copy", body)

export const cancelEpsmApplication = (ids: string[]): Promise<IResponse<any>> =>
    client.post("/EpsmApplications/Cancel", { ids }
    )

export const deleteEpsmApplication = (ids: string[]): Promise<IResponse<any>> =>
  client.delete("/EpsmApplications", {
      data: ids
  })

export const sendApplicationToElpts = (id: string) : Promise<IResponse<string>> =>
  client.post(`/EpsmApplications/SendApplicationToElpts?id=${id}`, null, {
      headers: {
          "Access-Control-Allow-Origin": "*",
          mode: "no-cors"
      },
      timeout: 6 * 60 * 1000 })

export const checkApplicationStatuses = (ids: string[], isStart: boolean) : Promise<IResponse<string>> =>
  client.put("/EpsmApplications/CheckApplicationStatuses",
    { ids,  isStart }
  )

export const createEpsmApplicationReport = (id: string): Promise<IResponse<number>> =>
    client.post(`/EpsmApplications/${id}/Report`)

export const getEpsmApplicationExample = (): Promise<IResponse<IDownloadDocument>> => client.get("/EpsmApplications/Example")

export const getEpsmApplicationExcel = (params?: IRequestParams, columns?: ITableColumn<IEpsmApplication>[]): Promise<IResponse<IDownloadDocument>> =>
    client.post("/EpsmApplications/Excel", columns, {
        params
    })