import { IClient } from "@/types/IClient"
import { ITableColumn } from "@/types/ui"

export const clientColumns: ITableColumn<IClient>[] = [
    {
        title: "Номер записи",
        key: "number",
        dataIndex: ["number"],
        sorter: true,
        width: 200,
        isVisible: true,
        order: 1
    },
    {
        title: "Наименование",
        key: "name",
        dataIndex: ["name"],
        sorter: true,
        width: 300,
        isVisible: true,
        order: 2
    },
    {
        title: "ИНН",
        key: "inn",
        dataIndex: ["inn"],
        sorter: true,
        width: 125,
        isVisible: true,
        order: 3
    },
    {
        title: "№ Р/СЧ в Банке",
        key: "bankAccount",
        dataIndex: ["bankAccount"],
        sorter: true,
        isVisible: false,
        order: 4
    },
    {
        title: "БИК",
        key: "bik",
        dataIndex: ["bik"],
        sorter: true,
        isVisible: false,
        order: 5
    },
    {
        title: "№ К/СЧ",
        key: "correspondentAccount",
        dataIndex: ["correspondentAccount"],
        sorter: true,
        isVisible: false,
        order: 6
    },
    {
        title: "Контактное лицо",
        key: "contactPerson",
        dataIndex: ["contactPerson"],
        width: 200,
        sorter: true,
        isVisible: true,
        align: "center",
        order: 7
    },
    {
        title: "Электронная почта",
        key: "email",
        dataIndex: ["email"],
        width: 200,
        sorter: true,
        isVisible: true,
        align: "center",
        order: 8
    },
    {
        title: "Номер телефона",
        key: "phone",
        dataIndex: ["phone"],
        width: 200,
        sorter: true,
        isVisible: true,
        align: "center",
        order: 9
    }
]