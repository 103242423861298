import { useCallback, useContext } from "react"

import { Resource, Scope } from "@/constants/permission"
import { PermissionContext } from "@/context/PermissionContext"

export const usePermission = () => {

  const context = useContext(PermissionContext)

  const hasPermission = useCallback((resource: Resource, scope: Scope) => {
    return context.some(w => w.rsname === resource && w.scopes.some(e => e === scope))
  }, [context])
  
  return {
    hasPermission
  }
}