import { FC } from "react"

import { Table } from "@/components/Table"
import { Resource } from "@/constants/permission"
import {
    TECHNICAL_VOCABULARIES_KEY,
    technicalVocabulariesColumns
} from "@/pages/TechnicalVocabularies/TechnicalVocabulariesColumns"

import { useTable } from "@/hook/useTable"

interface TechnicalVocabulariesProps {
    data: string[]
    title: string
}

const TechnicalVocabulariesPage: FC<TechnicalVocabulariesProps> = (props: TechnicalVocabulariesProps) => {
    const { columns, pagination, order } = useTable({
        initialColumns: technicalVocabulariesColumns,
        key: TECHNICAL_VOCABULARIES_KEY
    })

    return (
        <>
            <h1>{props.title}</h1>
            <Table
                tableName={TECHNICAL_VOCABULARIES_KEY}
                dataSource={props.data.map(x => ({ name: x }))}
                columns={columns.data}
                rowKey="name"
                pagination={{
                    currentPage: pagination.page,
                    pageSize: pagination.pageSize,
                    total: props.data.length,
                    onPageChange: pagination.onPageChange,
                    onPageSizeChange: pagination.onPageSizeChange
                }}
                onSorterChange={order.setOrderBy}
                permission={{
                    resource: Resource.EpsmApplication
                }}
            />
        </>
    )
}

export default TechnicalVocabulariesPage