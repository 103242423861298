import { FC } from "react"

import { OidcSecure } from "@axa-fr/react-oidc"
import { Layout } from "antd"
import { Outlet } from "react-router-dom"

import { Header } from "@/components/Layout/Header"
import { Sidebar } from "@/components/Layout/Sidebar"
import { WithSuspense } from "@/hoc/WithSuspanse"

import classes from "./MainLayout.module.scss"

export const MainLayout: FC = () => (
  <Layout className={classes.layout}>
    <Sidebar />
    <Layout>
      <Header />
      <Layout.Content className={classes.content}>
        <div className={classes["page-wrapper"]}>
          <WithSuspense>
            <OidcSecure>
              <Outlet />
            </OidcSecure>
          </WithSuspense>
        </div>
      </Layout.Content>
    </Layout>
  </Layout>
)