import { RegistrationType } from "./RegistrationType"

export interface IClient {
    id: number
    name: string
    shortName: string
    number: string
    isIndividualEntrepreneur: boolean
    inn: string
    kpp: string
    ogrn: string
    registrationType: RegistrationType
    bankAccount: string
    bankName: string
    bik: string
    correspondentAccount: string
    contactPerson: string
    phone: string
    email: string
    legalAddress: any
    jobGenitive: string
    requisitesGenitive: string
    fullNameGenitive: string
    jobNominative: string
    fullNameNominative: string
}

export interface IClientCreate {
    name: string
    shortName: string
    isIndividualEntrepreneur: boolean
    inn: string
    kpp: string
    ogrn: string
    bankAccount: string
    bankName: string
    bik: string
    correspondentAccount: string
    contactPerson: string
    phone: string
    email: string
    legalAddress: any
    jobGenitive: string
    requisitesGenitive: string
    fullNameGenitive: string
    jobNominative: string
    fullNameNominative: string
}

export interface IClientUpdate extends IClientCreate {
    id: number
}

export const CLIENTS_KEY = "CLIENTS_KEY"