import { useQuery } from "@tanstack/react-query"

import { SERIAL_NUMBER_QUERY_KEY } from "@/constants/query"
import { getSerialNumberById, getSerialNumbers } from "@/http/serialNumber"

import { IRequestParams } from "@/types/api"

export const useSerialNumberQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getSerialNumbers(params || {}),
    queryKey: [SERIAL_NUMBER_QUERY_KEY, params],
    retry: false
  })
}

export const useSerialNumberByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => getSerialNumberById(id),
    queryKey: [SERIAL_NUMBER_QUERY_KEY, id]
  })
}