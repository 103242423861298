import { useQuery } from "@tanstack/react-query"

import { COUNTRIES_QUERY_KEY } from "@/constants/query"
import { getCountries } from "@/http/country"

import { IRequestParams } from "@/types/api"

export const useCountryQuery = (params?: IRequestParams) => {
    return useQuery({
        queryFn: () => getCountries(params || {}),
        queryKey: [COUNTRIES_QUERY_KEY, params],
        retry: false
    })
}