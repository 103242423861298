export const getNested = (object: object, keys: string[]): string | null => {
  let result: string = ""
  let tempObj = object

  for (let i = 0; i < keys.length; i += 1) {
    if (!Object.hasOwn(tempObj, keys[i])) {
      return null
    }

    if (typeof tempObj[keys[i] as keyof object] === "object") {
      tempObj = tempObj[keys[i] as keyof object]
    } else {
      result = tempObj[keys[i] as keyof object]
    }
  }

  return result
}