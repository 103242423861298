import { IDocumentFile } from "./IDocumentFile"

import { IAdministrativeInfo } from "@/types/IAdministrativeInfo"
import { IApplicant } from "@/types/IApplicant"
import { IAuthor } from "@/types/IAuthor"
import { ICertificationBody } from "@/types/ICertificationBody"
import { IContractAnnex } from "@/types/IContractAnnex"
import { IGeneralProductName } from "@/types/IGeneralProductName"
import { IManufacturer } from "@/types/IManufacturer"
import { IOwner } from "@/types/IOwner"
import { IProductModel } from "@/types/IProductModel"
import { IProductModification } from "@/types/IProductModification"
import { IProductType } from "@/types/IProductType"
import { ISerialNumber } from "@/types/ISerialNumber"
import { IStatus } from "@/types/IStatus"
import { ITradeMark } from "@/types/ITradeMark"

export interface IEpsmApplication {
  id: string
  number: string
  author: IAuthor
  createdBy: IAuthor
  createdOn: string
  lastModifiedBy: IAuthor
  lastModifiedOn: string
  deletedBy: IAuthor
  deletedOn: string
  status: IStatus
  canChange: boolean
  reportDate: string
  passportNumber: string
  complianceDocument: {
    id: string
    number: string
  }
  applicant: IApplicant
  manufacturer: IManufacturer
  owner: IOwner
  generalProductName: IGeneralProductName
  tradeMark: ITradeMark
  productType: IProductType
  productModel: IProductModel
  certificationBody: ICertificationBody
  productModification: IProductModification
  tnvedCode: string
  additionalInfo: string
  administrativeInfo: IAdministrativeInfo
  manufacturerInfo: string
  serialNumber: ISerialNumber
  vehiclePictures?: IDocumentFile[]
  contractAnnex: IContractAnnex
  isCreatedManually: boolean
}

export interface IEpsmApplicationCreateValidateError {
  propName: string
  propFullRussianName: string
  value: string
  errorMessage: string
}

export interface IEpsmApplicationCreateResponse {
  id: string
  isValid: boolean
  confirmationId: number
  validationErrors: IEpsmApplicationCreateValidateError[]
}

export interface IEpsmApplicationCreateDto extends IEpsmApplicationUpdateDto {
  statusId: number
  tradeMarkId: number
  productModelId: number
  productTypeId: number
  productModificationId: number
  tnvedCode: string 
  generalProductNameId: number
}

export interface IEpsmApplicationUpdateDto {
  id: string
  authorId?: string
  passportNumber: string
  complianceDocumentId: string
  serialNumber: Pick<ISerialNumber, "idNumber" | "vehicleIdentification" | "labeling" | "generalCharacteristics">
  administrativeInfo: IAdministrativeInfo
  additionalInfo: string
  manufacturerInfo: string
  vehiclePictures?: IDocumentFile[]
  manufacturerId: string
  applicantId: string
  ownerId: number
  contractAnnexId: string
  withApproval: boolean
}

export interface IEpsmApplicationCopyDto {
  id: string
  serialNumber: string
}


export enum EpsmApplicationStatus {
  New,
  InWork,
  Approved,
  Sent,
  Rejected,
  Completed,
  Deleted,
  Canceled,
  InQueue
}

export const EPSM_APPLICATIONS_KEY = "EPSM_APPLICATIONS_KEY"
export const EPSM_APPLICATIONS_IN_CONTRACT_ANNEX_KEY = "EPSM_APPLICATIONS_IN_CONTRACT_ANNEX_KEY"