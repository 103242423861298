import { useQuery } from "@tanstack/react-query"

import { CERTIFICATION_BODIES_QUERY_KEY } from "@/constants/query"
import { getCertificationBodies, getCertificationBodyById } from "@/http/certificationBody"

import { IRequestParams } from "@/types/api"

export const useCertificationBodyQuery = (params?: IRequestParams) => {
  return useQuery({
    queryFn: () => getCertificationBodies(params || {}),
    queryKey: [CERTIFICATION_BODIES_QUERY_KEY, params],
    retry: false
  })
}

export const useCertificationBodyByIdQuery = (id: number) => {
  return useQuery({
    queryFn: () => getCertificationBodyById(id),
    queryKey: [CERTIFICATION_BODIES_QUERY_KEY, id],
    enabled: id !== 0
  })
}